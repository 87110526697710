<section class="free-quote">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 align-self-center">
        <div class="section-heading">
          
          <h2 style="font-size: xx-large;color: black;">Digital Marketing</h2>
        </div>
        <form action="#">
          <div class="row">
            <div class="col-lg-10">
              <p style="color: white;font-size: initial;font-weight: 500; margin-top: -6%;
              text-align: justify;">Our digital marketing services help you strategize, implement, run and maintain successful omni-channel marketing and multi-lingual digital marketing solutions.</p>
            </div>
            
            <div class="col-lg-12">
              <button style="margin-top: 3%;">Read More..</button>
            </div>
          </div>
        </form>
      </div>

      <div class="col-lg-6 align-self-center">
        <div class="left-image">
          <img src="assets/frontend/images/treeimages/blog2.png" style="width: 180%;margin-left: -51%;" alt="">
        </div>
      </div>
      
    </div>

    <div class="row">

      <div class="col-lg-6 align-self-center">
        <div class="left-image">
          <img src="assets/frontend/images/treeimages/blog3.jpg" style="width: 139%;margin-left: -106px;" alt="">
        </div>
      </div>

      <div class="col-lg-6 align-self-center">
        <div class="section-heading">
          
          <h2 style="font-size: xx-large;color: black;">Application Development</h2>
        </div>
        <form action="#">
          <div class="row">
            <div class="col-lg-10">
              <p style="color: white;font-size: initial;font-weight: 500; margin-top: -6%;
              text-align: justify;">In today’s 24/7 business environment, application failure or unexpected downtime can frustrate end users and devastate your bottom line. As budgets shrink and application environments grow more complex, it pays to have an experienced IT strategy partner who can specialize in application development and application support.</p>
            </div>
            
            <div class="col-lg-12">
              <button style="margin-top: 3%;">Read More..</button>
            </div>
          </div>
        </form>
      </div>

    </div>

    <div class="row">
      <div class="col-lg-6 align-self-center">
        <div class="section-heading">
          
          <h2 style="font-size: xx-large;color: black;">WEB DEVELOPMENT</h2>
        </div>
        <form action="#">
          <div class="row">
            <div class="col-lg-10">
              <p style="color: white;font-size: initial;font-weight: 500; margin-top: -6%;
              text-align: justify;">We provide Fully responsive designs which support every device. We provide both Web designing and web development. We have worked on various world class websites all over the world.</p>
            </div>
            
            <div class="col-lg-12">
              <button style="margin-top: 3%;">Read More..</button>
            </div>
          </div>
        </form>
      </div>

      <div class="col-lg-6 align-self-center">
        <div class="left-image">
          <img src="assets/frontend/images/treeimages/blog4.png" style="width: 180%;margin-left: -51%;" alt="">
        </div>
      </div>
      
    </div>

    <div class="row">

      <div class="col-lg-6 align-self-center">
        <div class="left-image">
          <img src="assets/frontend/images/treeimages/blog5.png" style="width: 180%;margin-left: -45%;" alt="">
        </div>
      </div>

      <div class="col-lg-6 align-self-center">
        <div class="section-heading">
          
          <h2 style="font-size: xx-large;color: black;">SOFTWARE DEVELOPMENT</h2>
        </div>
        <form action="#">
          <div class="row">
            <div class="col-lg-10">
              <p style="color: white;font-size: initial;font-weight: 500; margin-top: -6%;
              text-align: justify;">Highly Customized software solutions that support a unique combination of platforms and applications that address cross-functional business requirements of our clients. We provide affordable and efficient softwares.</p>
            </div>
            
            <div class="col-lg-12">
              <button style="margin-top: 3%;">Read More..</button>
            </div>
          </div>
        </form>
      </div>

    </div>

   
  </div>
</section>