 <!-- News Events -->
 <section class="recent-cases portfolio-page">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="section-heading">
          <h4 style="font-size: xx-large;">News</h4>
          <h1 style="color: #009df2;font-size: xxx-large;">Events</h1>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="row masonry-layout filters-content normal-col-gap">
          <div class="col-lg-4 masonry-item all category-analysis">
            <div class="case-item">
              <a routerLink="/none">
              <div class="case-thumb">
                <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Eclient%2Fe1.jpg?alt=media&token=e6e44a43-8e57-4d03-a8c5-5c120f33f9f0" alt="">
              </div>
              <div class="down-content">
                <h4>Workshop by Senior Advisor</h4>
                <ul style="list-style-type: disc;text-align: left;color: black; font-size: 12px;     padding-bottom: 18px;
                padding-top: 24px;">
                  <li class="text-justify">Workshop was enacted by Vaibhav Shrivastava, who is Product Owner at Xiaomi , China</li>
                  <li class="text-justify">He is our Senior Advisor, he conducted the workhop in which he holistically motivated the team of PN INFOSYS.</li>
                  
                </ul>
                <br>
                <!-- <div class="post-meta">
                  <span><i class="fa fa-calendar" aria-hidden="true"></i> August 20, 2018 - 10:00 AM</span>
                  <br>
                  <span><i class="fa fa-user" aria-hidden="true"></i> By <a routerLink="/none">Admin</a></span>
                </div> -->
                <a class="btn btn-xs btn-primary" routerLink="/none">Read more...</a>
              </div>
              </a>
            </div>
          </div>
          <div class="col-lg-4 masonry-item all category-analysis">
            <div class="case-item">
              <a routerLink="/none">
              <div class="case-thumb">
                <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Eclient%2Fe2.jpg?alt=media&token=858212a2-77c6-4932-9a26-06eeccb44168" alt="">
              </div>
              <div class="down-content">
                <h4>PN INFOSYS invited as Chief Guest</h4>
                <ul style="list-style-type: disc;text-align: left;color: black; font-size: 12px;">
                  <li class="text-justify">Rustamji Institute of Technology, BSF Academy, Tekanpur invited PN INFOSYS as a Cheif Guest in their Software Tech-Fest.</li>
                  <li class="text-justify">Rustamji Institute of Technology, BSF Academy, Tekanpur invited PN INFOSYS as a Cheif Guest in their Software Tech-Fest.Tekanpur invited PN INFOSYS as a Cheif</li>
                  
                </ul>
                <br>
                <!-- <div class="post-meta">
                  <span><i class="fa fa-calendar" aria-hidden="true"></i> August 20, 2018 - 10:00 AM</span>
                  <br>
                  <span><i class="fa fa-user" aria-hidden="true"></i> By <a href="#">Admin</a></span>
                </div> -->
                <a class="btn btn-xs btn-primary" routerLink="/none">Read more...</a>
              </div>
              </a>
            </div>
          </div>
          <div class="col-lg-4 masonry-item all category-analysis">
            <div class="case-item">
              <a routerLink="/none">
              <div class="case-thumb">
                <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Eclient%2Fe3.jpg?alt=media&token=0f9d6230-34e3-4233-b31f-a6c5e646bbcd" alt="">
              </div>
              <div class="down-content">
                <h4>Social Applaud by MPCT college</h4>
                <ul style="list-style-type: disc;text-align: left;color: black; font-size: 12px; padding-top: 24px;
                padding-bottom: 17px;">
                  <li class="text-justify">PN INFOSYS gave two months training sessions to MPCT College.</li>
                  <li class="text-justify">This training was meant for web designing to make sure that at the end of the two months, students become capable enough to master Web Designing.</li>
                  
                </ul>
                <br>
                <!-- <div class="post-meta">
                  <span><i class="fa fa-calendar" aria-hidden="true"></i> August 20, 2018 - 10:00 AM</span>
                  <br>
                  <span><i class="fa fa-user" aria-hidden="true"></i> By <a href="#">Admin</a></span>
                </div> -->
                <a class="btn btn-xs btn-primary" routerLink="/none">Read more...</a>
              </div>
              </a>
            </div>
          </div>
          <div class="col-lg-4 masonry-item all category-analysis">
            <div class="case-item">
              <a routerLink="/none">
              <div class="case-thumb">
                <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Eclient%2Fe4.jpg?alt=media&token=33d539d8-9574-40ff-85f7-ae4ce208f65e" alt="">
              </div>
              <div class="down-content">
                <h4>Social Applaud for our Work</h4>
                <ul style="list-style-type: disc;text-align: left;color: black; font-size: 12px; padding-bottom: 6px;">
                  <li class="text-justify">PN INFOSYS did a project for Samaysaar Vidhyaniketan , which is a non-profit institution, it provides free schooling , free accommodation and food to poor Jain students.</li>
                  
                </ul>
               <br>
                <!-- <div class="post-meta">
                  <span><i class="fa fa-calendar" aria-hidden="true"></i> August 20, 2018 - 10:00 AM</span>
                  <br>
                  <span><i class="fa fa-user" aria-hidden="true"></i> By <a routerLink="/none">Admin</a></span>
                </div> -->
                <a class="btn btn-xs btn-primary" routerLink="/none">Read more...</a>
              </div>
              </a>
            </div>
          </div>
          <div class="col-lg-4 masonry-item all category-analysis">
            <div class="case-item">
              <a routerLink="/none">
              <div class="case-thumb">
                <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Eclient%2Fe5.jpg?alt=media&token=1a40c8b1-c469-48b6-b0ad-608edae9543c" alt="">
              </div>
              <div class="down-content">
                <h4>New Branch Opening in Indore</h4>
                <ul style="list-style-type: disc;text-align: left;color: black; font-size: 12px; padding-bottom: 41px;">
                  <li class="text-justify">PN INFOSYS has a new branch in Indore also.</li>
                  <li class="text-justify">It was a grand launch of our new branch in Indore.</li>
                  
                </ul>
                <br>
                <!-- <div class="post-meta">
                  <span><i class="fa fa-calendar" aria-hidden="true"></i> August 20, 2018 - 10:00 AM</span>
                  <br>
                  <span><i class="fa fa-user" aria-hidden="true"></i> By <a href="#">Admin</a></span>
                </div> -->
                <a class="btn btn-xs btn-primary" routerLink="/none">Read more...</a>
              </div>
              </a>
            </div>
          </div>
          <div class="col-lg-4 masonry-item all category-analysis">
            <div class="case-item">
              <a routerLink="/none">
              <div class="case-thumb">
                <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Eclient%2Fe6.jpg?alt=media&token=d293bbea-2474-4b0e-99c2-1734428c81fc" alt="">
              </div>
              <div class="down-content">
                <h4>Workshop by Senior Advisor</h4>
                <ul style="list-style-type: disc;text-align: left;color: black; font-size: 12px; padding-bottom: 41px;">
                  <li class="text-justify">He is our Senior Advisor, he conducted the workhop in which he holistically motivated the team of PN INFOSYS.</li>
                  
                </ul>
                
                <!-- <div class="post-meta">
                  <span><i class="fa fa-calendar" aria-hidden="true"></i> August 20, 2018 - 10:00 AM</span>
                  <br>
                  <span><i class="fa fa-user" aria-hidden="true"></i> By <a href="#">Admin</a></span>
                </div> -->
                <a class="btn btn-xs btn-primary" routerLink="/none">Read more...</a>
              </div>
              </a>
            </div>
          </div>

        </div>
      </div>
      
      <div class="col-lg-12">
        <!-- <div class="main-purple-button">
          <a routerLink="/none">View More Events</a>
        </div> -->
      </div>
    </div>
  </div>
</section>