import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-placementdesk',
  templateUrl: './placementdesk.component.html',
  styleUrls: ['./placementdesk.component.css']
})
export class PlacementdeskComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
