
<!-- <div id="search">
    <button type="button" class="close">×</button>
      <form>
          <input type="search" value="" placeholder="Type to search..." required="">
          <button type="submit" class="primary-button"><i class="fa fa-search"></i></button>
      </form>
  </div> -->
<div class="main-content">
  
<div class="page-heading">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h1>About Us</h1>
          <span><a href="#">Home</a>About Us</span>
        </div>
      </div>
    </div>
  </div>

  
  <!-- Steps -->
  <section class="steps">
    <div class="section-heading">
      <h4 style="font-size: xx-large;text-align: center;">We are the best</h4>
      <h1 style="color: #009df2;font-size: xxx-large;text-align: center;">For all your needs</h1>
    </div>
      <br> 
    <div class="container">
      
      <div class="row">
        <div class="col-lg-3">
          <div class="step-item">
            <div class="item-number">
              <h6>01</h6>
            </div>
            <div class="item-content">
             <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FFor-all-your-needs%2Fa1-removebg-preview.png?alt=media&token=a5352e82-184a-4a0b-89ce-f31a898d2bb3" alt="">
             
            </div><br>
            <h4 style="width: 75%;text-align: center;margin-left: 7%;">Consult your idea with us!</h4>
            <div class="item-arrow">
              <i class="fa fa-angle-right"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="step-item">
            <div class="item-number">
              <h6>02</h6>
            </div>
            <div class="item-content">
              <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FFor-all-your-needs%2Fa1-removebg-preview.png?alt=media&token=a5352e82-184a-4a0b-89ce-f31a898d2bb3" alt="">
            </div><br>
            <h4 style="width: 75%;text-align: center;margin-left: 7%;">We'll Develop your idea</h4>
            <div class="item-arrow">
              <i class="fa fa-angle-right"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="step-item">
            <div class="item-number">
              <h6>03</h6>
            </div>
            <div class="item-content">
              <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FFor-all-your-needs%2Fa3.png?alt=media&token=9999aa98-d5d5-4af8-9601-809c5d2f7ffd" alt="">
            </div><br>
            <h4 style="width: 75%;text-align: center;margin-left: 7%;">We'll Digital Market your idea.
            </h4>
            <div class="item-arrow">
              <i class="fa fa-angle-right"></i>
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="step-item">
            <div class="item-number">
              <h6>04</h6>
            </div>
            <div class="item-content">
              <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FFor-all-your-needs%2Fa4-removebg-preview.png?alt=media&token=443a3899-cb40-4759-95d2-93c21623682d" alt="">
            </div><br>
            <h4 style="width: 75%;text-align: center;margin-left: 7%;">Client's happiness
            </h4>
          </div>
        </div>
      </div>
    </div>
  </section>

  
  <!-- About Tips -->
  <section class="about-tips">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-heading">
            <h6></h6>
            <h2>Who &amp; We are?</h2>
          </div>
          <p class="text-justify text-medium">We are a one-stop destination for all digital solution, be it website designing,web development, digital marketing, SEO, mobile apps and full
            maintenance and compliance services for Government and Commercial facilities both large and small.. Our elegant group of Developers provide their innovation who transform your idea into an amazing website  Design or Mobile App Development while keeping every custom project unique.<br><br>We are part of this IT industry since 2018, we not only developed products and websites but also provides internship and trainning to students and make them capable to work in this It software industry, our internship and trainning program is totally based on hand to hand pratical with live projects.<br><br>Our team of IT professionals certified professionals services Dental Offices, Medical Offices, Restaurants, Bars and all types of businesses throughout the Lowcountry and the world.
            Our team of IT professionals certified professionals services  Hosptials, Colleges,  Research Institutes, Schools, Restaurants, Bars and all types of businesses throughout the Lowcountry and the world.
          </p>
         
        </div>
        <div class="col-lg-6 align-self-center">
          <div class="video-thumb">
         
            <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FWho-%26-We-Are%3F%2Ftemplate.png?alt=media&token=ade88e5d-901f-4037-b97d-d19e3b9c442d" alt="" class="img-fluid">
           
       

          <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FWho-%26-We-Are%3F%2Fplayicon.png?alt=media&token=ebed2050-72f3-4946-a17e-12fe48c775fd" class=" play-btn" data-toggle="modal" data-target="#exampleModalCenter">
       </div>
        <div class="modal fade" id="exampleModalCenter" >
          <div class="modal-dialog modal-dialog-centered ">
        
            <div class="modal-content" data-target="modal-box">
              <!-- <button type="button" class="close" data-dismiss="modal" aria-hidden="true" id="videoToggleOff"><h4 class="videoText">X</h4></button> -->
              <div class="modal-body" id="modal-video iframe" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-hidden="true">
                   <!-- <video width="100%" height="410"    controls loop="loop">
                     <source src="assets/images/video/memories.mp4" type="video/mp4">
                    </video> -->
                   <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FWho-%26-We-Are%3F%2Flearn.jpg?alt=media&token=b95d388f-e1c0-457f-8835-dae0390077b7">
                  </div>
             

              
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </section>


  

   <!-- Our Services -->
   <app-cases></app-cases>
 


  <!-- Our Team -->
  <section class="our-team">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-heading">
            <h3>Our Team</h3>
            <h2>Meet Our Team Members</h2>
          </div>
        </div>
        
        <div class="col-lg-3">
          <div class="team-item">
            <div class="team-thumb">
              <div class="hover-effect">
                <ul>
                  <li><a href="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fvkj.png?alt=media&token=c2e9af3e-25bc-478e-b758-73dd296d67e6" target="_blank" class="fancybox-pop fancybox.image"><i class="fa fa-search fa-border fa-2x"></i></a></li>
                </ul>
              </div>
              <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fvkj.png?alt=media&token=c2e9af3e-25bc-478e-b758-73dd296d67e6" alt="">
            </div>
            <div class="down-content">
              <h4>Vikas Jain</h4>
              <span>Director PNINFOSYS </span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="team-item">
            <div class="team-thumb">
              <div class="hover-effect">
                <ul>
                  <li><a href="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fvaibhav.jpg?alt=media&token=71a35861-d268-488b-8699-ac6108881d4c" target="_blank" class="fancybox-pop fancybox.image"><i class="fa fa-search fa-border fa-2x"></i></a></li>
                  
                </ul>
              </div>
              <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fvaibhav.jpg?alt=media&token=71a35861-d268-488b-8699-ac6108881d4c" alt="" >
            </div>
            <div class="down-content">
              <h4> Vaibhav Sir</h4>
              <span>Senior Advisor</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="team-item">
            <div class="team-thumb">
              <div class="hover-effect">
                <ul>
                  <li><a href="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fniket.jpg?alt=media&token=4ea14f42-85c3-48ca-9dfa-8c0817bf1563" target="_blank" class="fancybox-pop fancybox.image"><i class="fa fa-search fa-border fa-2x"></i></a></li>
                </ul>
              </div>
              <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fniket.jpg?alt=media&token=4ea14f42-85c3-48ca-9dfa-8c0817bf1563" alt="">
            </div>
            <div class="down-content">
              <h4> Niket Bansal</h4>
              <span>Senior Advisor</span>
            </div>
          </div>
        </div>
        
      
        <div class="col-lg-3">
          <div class="team-item">
            <div class="team-thumb">
              <div class="hover-effect">
                <ul>
                  <li><a href="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Frishi.jpg?alt=media&token=0a7f3772-d8c0-465e-b527-0faea353ed0a" target="_blank" class="fancybox-pop fancybox.image"><i class="fa fa-search fa-border fa-2x"></i></a></li>
                </ul>
              </div>
              <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Frishi.jpg?alt=media&token=0a7f3772-d8c0-465e-b527-0faea353ed0a" alt="">
            </div>
            <div class="down-content">
              <h4>Rishi Jha</h4>
              <span>General Manager</span>
            </div>
          </div>
        </div>
        
        <div class="col-lg-3">
          <div class="team-item">
            <div class="team-thumb">
              <div class="hover-effect">
                <ul>
                  <li><a href="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fceo.png?alt=media&token=4722e889-dc9d-40b4-8734-ac760495b08f" target="_blank" class="fancybox-pop fancybox.image"><i class="fa fa-search fa-border fa-2x"></i></a></li>
                </ul>
              </div>
              <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fceo.png?alt=media&token=4722e889-dc9d-40b4-8734-ac760495b08f" alt="">
            </div>
            <div class="down-content">
              <h4>Neha<br> Jain</h4>
              <span>CEO PNINFOSYS</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="team-item">
            <div class="team-thumb">
              <div class="hover-effect">
                <ul>
                  <li><a href="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fshivam-min.jpg?alt=media&token=5f4381dc-ab1d-4a5d-8ae7-7da24d0711d3" target="_blank" class="fancybox-pop fancybox.image"><i class="fa fa-search fa-border fa-2x"></i></a></li>
                </ul>
              </div>
              <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fshivam-min.jpg?alt=media&token=5f4381dc-ab1d-4a5d-8ae7-7da24d0711d3" alt="">
            </div>
            <div class="down-content">
              <h4>shivam Shrivastava</h4>
              <span>HR/Developer</span>
            </div>
          </div>
        
        </div>
      
        <div class="col-lg-3">
          <div class="team-item">
            <div class="team-thumb">
              <div class="hover-effect">
                <ul>
                  <li><a href="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fchanchal.jpg?alt=media&token=4f020a95-4d8e-42a3-92d7-6a5c5d79a50c" target="_blank" class="fancybox-pop fancybox.image"><i class="fa fa-search fa-border fa-2x"></i></a></li>
                </ul>
              </div>
              <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fchanchal.jpg?alt=media&token=4f020a95-4d8e-42a3-92d7-6a5c5d79a50c" alt="">
            </div>
            <div class="down-content">
              <h4>Chanchal<br> Sharma</h4>
              <span>Developer</span>
            </div>
          </div>
        
        </div>
        <div class="col-lg-3">
          <div class="team-item">
            <div class="team-thumb">
              <div class="hover-effect">
                <ul>
                  <li><a href="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fvishakha.png?alt=media&token=2c53aabf-2341-4d28-a597-3f35d403b46f" target="_blank" class="fancybox-pop fancybox.image"><i class="fa fa-search fa-border fa-2x"></i></a></li>
                </ul>
              </div>
              <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fvishakha.png?alt=media&token=2c53aabf-2341-4d28-a597-3f35d403b46f" alt="">
            </div>
            <div class="down-content">
              <h4>Vishakha<br> Saxena</h4>
              <span>Intern</span>
            </div>
          </div>
        
        </div>
        <div class="col-lg-3">
          <div class="team-item">
            <div class="team-thumb">
              <div class="hover-effect">
                <ul>
                  <li><a href="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fyash.png?alt=media&token=3ffa7826-0688-46e8-b94a-6594c08feff6" target="_blank" class="fancybox-pop fancybox.image"><i class="fa fa-search fa-border fa-2x"></i></a></li>
                </ul>
              </div>
              <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fyash.png?alt=media&token=3ffa7826-0688-46e8-b94a-6594c08feff6" alt="">
            </div>
            <div class="down-content">
              <h4>Yash<br> Bhatt</h4>
              <span>Intern</span>
            </div>
          </div>
        
        </div>
        <div class="col-lg-3">
          <div class="team-item">
            <div class="team-thumb">
              <div class="hover-effect">
                <ul>
                  <li><a href="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Faman.jpeg?alt=media&token=1a993fe2-eb2c-43f1-8e47-35db4f170864" target="_blank" class="fancybox-pop fancybox.image"><i class="fa fa-search fa-border fa-2x"></i></a></li>
                </ul>
              </div>
              <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Faman.jpeg?alt=media&token=1a993fe2-eb2c-43f1-8e47-35db4f170864" alt="">
            </div>
            <div class="down-content">
              <h4>Aman<br> Shrivastava</h4>
              <span>Intern</span>
            </div>
          </div>
        
        </div>
        <div class="col-lg-3">
          <div class="team-item">
            <div class="team-thumb">
              <div class="hover-effect">
                <ul>
                  <li><a href="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fkanishka.jpeg?alt=media&token=03bc9233-8066-45a1-8b8a-5725a57393fe" target="_blank" class="fancybox-pop fancybox.image"><i class="fa fa-search fa-border fa-2x"></i></a></li>
                </ul>
              </div>
              <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fkanishka.jpeg?alt=media&token=03bc9233-8066-45a1-8b8a-5725a57393fe" alt="">
            </div>
            <div class="down-content">
              <h4>Kanishka<br>Chaturvedi</h4>
              <span>Intern</span>
            </div>
          </div>
        
        </div>

        <div class="col-lg-3">
          <div class="team-item">
            <div class="team-thumb">
              <div class="hover-effect">
                <ul>
                  <li><a href="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fnidhi.jpeg?alt=media&token=146932cd-7f3c-4f27-b248-bda03d9227f1" target="_blank" class="fancybox-pop fancybox.image"><i class="fa fa-search fa-border fa-2x"></i></a></li>
                </ul>
              </div>
              <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fnidhi.jpeg?alt=media&token=146932cd-7f3c-4f27-b248-bda03d9227f1" alt="">
            </div>
            <div class="down-content">
              <h4>Nidhi <br>Khandelwal </h4>
              <span>Intern</span>
            </div>
          </div>
        
        </div>

        <div class="col-lg-3">
          <div class="team-item">
            <div class="team-thumb">
              <div class="hover-effect">
                <ul>
                  <li><a href="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fshristi.jpeg?alt=media&token=2470093c-9f39-476b-b1e5-18006e7b5466" target="_blank" class="fancybox-pop fancybox.image"><i class="fa fa-search fa-border fa-2x"></i></a></li>
                </ul>
              </div>
              <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2FOur-Team%2Fshristi.jpeg?alt=media&token=2470093c-9f39-476b-b1e5-18006e7b5466" alt="">
            </div>
            <div class="down-content">
              <h4>Shristi Gupta<br></h4>
              <span>Intern</span>
            </div>
          </div>
        
        </div>

      </div>
    </div>
  </section>
<app-rowslider></app-rowslider>
  
  
  <script type="text/javascript">
    $(document).ready(function() {
   $('video').prop('muted',true).play()
   });
   $('#exampleModalCenter').on('hidden.bs.modal', function () {
  $("#modal-video iframe").attr("src", $("#modal-video iframe").attr("src"));
  });

</script>


</div>
