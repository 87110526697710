
<!-- <app-loder></app-loder> -->
<app-banner></app-banner>
<app-feature></app-feature>
<app-tips></app-tips>
<app-cases></app-cases>


<!-- <app-quote></app-quote> -->
<app-courses></app-courses>
<!-- <app-clients></app-clients> -->
<app-services></app-services>
<app-client></app-client>

