

<div class="main-content">
      
    <!-- Page Heading -->
    <div class="page-heading">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1>Our Services</h1>
            <span><a href="#">Home</a>Our Services</span>
          </div>
        </div>
      </div>
    </div>

<!-- Features -->
<section class="features">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h5 class="text-justify" style=" text-align: center; margin-top: 31px;">"PN INFOSYS is a leading global business consulting and IT service company. We provides a full range of maintenance and compliance services for Government and Commercial facilities both large and small. Whether you need to run your business more efficiently or accelerate revenue growth, PN INFOSYS can get you there.
          Our team is proficient enough to provide all the IT services, which a customer needs in an affordable rates. We make sure our clients are happy at the end of the day."</h5>
      </div>
      <div class="col-lg-4">
       
        <div class="feature-item">
          <div class="icon">
            <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/services%2F3.jpg?alt=media&token=085d7c11-9810-4d8d-b9e1-f05975f34fa8" alt="">
          </div>
          <h4>Innovative Ideas</h4>
          
        </div>
      </div>
      <div class="col-lg-4">
        <div class="feature-item">
          <div class="icon">
            <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/services%2F1.jpg?alt=media&token=3c5ec41f-f3c7-43cd-8ecd-f966f8516fdf" alt="">
          </div>
          <h4>Creative Designing</h4>
        </div>
        
      </div>
      <div class="col-lg-4">
        <div class="feature-item">
          <div class="icon">
            <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/services%2F2.jpg?alt=media&token=a0ea0e45-becc-4e61-a927-fef6a538b2a8" alt="">
          </div>
          <h4>Client's Happiness</h4>
        </div>
        
      </div>
    </div>
  </div>
</section>

    <!-- Good Tips -->
    <section class="good-tips" style="margin: 0px;">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 align-self-center">
            <div class="section-heading">
              <!-- <h6>Tips For You!</h6> -->
              <h1 style="color:white;">PN Services</h1>
              <h4 class="text-justify text-align" style="color:white;">PN INFOSYS provides the best service possible to its customers because for us, our client’s happiness is important. Whatever we choose to do, we do it an exorbitant manner.</h4>
            </div>
            <div style="color:white;">
              <ul>
                <li class="text-justify text-align">PN INFOSYS Company provides a full range of maintenance and compliance services for Government and Commercial facilities both large and small.</li>
            <br>  <li class="text-justify text-align">PN INFOSYS believes in developing true partnerships. We foster a collegial environment where individual perspectives are respected and honest dialogue is expected.</li>
            <br> <li class="text-justify text-align">PN INFOSYS brings robust skills and forward looking perspectives to solve customer challenges. We use proven knowledge to make recommendations and provide expert guidance to our customers.</li> 
            <br> <li class="text-justify text-align">PN INFOSYS is driven to meet client needs with determination and grit. We embrace tough challenges and do not rest until the problem is solved, the right way.</li> 
          </ul>
            </div>
          </div>
          <div class="col-lg-6 align-self-center">
            <div class="right-image">
              <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/services%2Fpnservice.png?alt=media&token=883cdc9d-d455-46f2-86f9-4a8c55f8333d" alt="">
            </div>
          </div>
        </div>
      </div>
    </section>

    
    
 

  </div>

