

<div class="page-heading">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h1>Company Tour's</h1>
          <span><a href="index.html">Home</a>Pninfosys</span>
        </div>
      </div>
    </div>
  </div>

  
  <!-- Recent Cases -->
  <section class="recent-cases portfolio-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-heading">
            <h6 class="text-center">Enjoying Moments</h6>
            <h2 class="text-center">Pninfosys Team</h2>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="row masonry-layout filters-content normal-col-gap">
            <!-- <div class="col-lg-4 masonry-item all category-analysis">
              <div class="case-item">
                <a href="assets/frontend/images/event/tour/ture1.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/tour/ture1.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div> -->
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/tour/ture2.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/tour/ture2.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-digital">
              <div class="case-item">
                <a href="assets/frontend/images/event/tour/true3.png" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/tour/true3.png" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-digital">
              <div class="case-item">
                <a href="assets/frontend/images/event/tour/ture4.png" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/tour/ture4.png" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-analysis">
              <div class="case-item">
                <a href="assets/frontend/images/event/tour/ture5.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/tour/ture5.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/tour/ture6.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/tour/ture6.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/tour/ture7.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/tour/ture7.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/tour/ture8.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/tour/ture8.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/tour/ture9.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/tour/ture9.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/tour/ture10.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/tour/ture10.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/tour/ture11.png" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/tour/ture11.png" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/tour/ture12.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/tour/ture12.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/tour/ture13.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/tour/ture13.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/tour/ture14.png" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/tour/ture14.png" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
           
            
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/tour/ture16.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/tour/ture16.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture17.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture17.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture18.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture18.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture19.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture19.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture20.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture20.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture21.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture21.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture22.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture22.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture23.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture23.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture24.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture24.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture25.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture25.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture26.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture26.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture27.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture27.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture28.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture28.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture29.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture29.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture30.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture30.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture32.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture32.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture33.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture33.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture34.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture34.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture35.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture35.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture36.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture36.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture37.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture37.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture38.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture38.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture39.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture39.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture40.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture40.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture41.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture41.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture43.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture43.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture44.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture44.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              
              
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture46.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture46.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture47.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture47.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
             
              
              <div class="col-lg-4 masonry-item all category-seo">
                <div class="case-item">
                  <a href="assets/frontend/images/event/tour/ture49.jpg" class="zoom" target="">
                  <div class="case-thumb">
                    <img src="assets/frontend/images/event/tour/ture49.jpg" alt="" style="margin-top: 0px;">
                  </div>
                  </a>
                </div>
              </div>
          </div>
          </div>
      </div>
    </div>
  </section>
