
<div class="main-content">
     <!-- Main Banner -->
  <div class="parallax-banner">
    <!--Content before waves-->
    <div class="inner-header"><br><br><br><br><br><br>
      <div class="inner-content">
       
        <h1 style="text-shadow: 0px 0px 5px #000000;">Placement  Gallery</h1>
       
        <div class="main-decoration">
            <br>
            <br>
            <br>
            <br>
      
        </div>
      </div>
    </div>

    
   
  </div>

    <div class="container">
        <br><br>
        <div row>
            <div class="col-lg-4">
                <div class="section-heading">
                 <h2> Placement Gallery</h2>
                  <h6>List of our placed students</h6>
                  
                
              </div>
            </div>
              <div class="col-lg-8">
                <!-- <div class="portfolio-filters">
                  <ul>
                      <li class="active" data-filter="*">Show All</li>
                      <li data-filter=".category-analysis">2018</li>
                      <li data-filter=".category-digital">2019</li>
                      <li data-filter=".category-seo">2020</li>
                  </ul>
                </div> -->
              </div>
            

              <div class="row">
                <div class="courses-box3 col-sm-12 col-lg-6  col-md-6">
                    <div class="single-item-wrapper category-digital">
                        <div class="courses-img-wrapper hvr-bounce-to-right">
                             <img src="assets/images/placement/placement2019/ritikbansal.png" alt="placement gallery" class="img-responsive center-block img-fluid">
                          
                        </div>
                        <div class="courses-content-wrapper">
                          
                          <div class="table-responsive">
                          <table class="table table-bordered table-responsive text-center text-uppercase text-bold" >
                          <thead>
                            <tr>
                                <th>NAME</th>
                                <td>ritik bansal</td>
                          
                               
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>COMPANY NAME </th>
                                <td>expert webtech</td>
                           
                            
                            </tr>
                           
                                <tr>
                                <th>DESIGNATION	</th>
                                <td>Full Stack Developer</td>
                            
                              
                            </tr>
                        </tbody>
                    </table>
                </div>
                        </div>
                    </div>
                </div>
                <div class="courses-box3 col-sm-12 col-lg-6 col-md-6 ">
                    <div class="single-item-wrapper category-digital">
                        <div class="courses-img-wrapper hvr-bounce-to-right">
                             <img src="assets/images/placement/placement2019/shivraj.png" alt="placement gallery" class="img-responsive img-fluid center-block">
                          
                        </div>
                        <div class="courses-content-wrapper">
                          
                          <div class="table-responsive">
                    <table class="table table-bordered table-responsive text-center text-uppercase text-bold" >
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <td>Shivraj dhakad</td>
                          
                               
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>COMPANY NAME </th>
                                <td>First fiddle</td>
                           
                            
                            </tr>
                           
                                <tr>
                                <th>DESIGNATION	</th>
                                <td>Full Stack Developer</td>
                            
                              
                            </tr>
                        </tbody>
                    </table>
                </div>
                        </div>
                    </div>
                </div>
        </div> 
        <div class="row">
                <div class="courses-box3 col-sm-12 col-lg-6  col-md-6 ">
                    <div class="single-item-wrapper category-digital">
                        <div class="courses-img-wrapper hvr-bounce-to-right">
                             <img src="assets/images/placement/placement2019/nishapraveen1.png" alt="placement gallery" class="img-responsive img-fluid center-block">
                          
                        </div>
                        <div class="courses-content-wrapper">
                          
                          <div class="table-responsive">
                    <table class="table table-bordered table-responsive text-center text-uppercase text-bold" >
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <td>nisha praveen</td>
                          
                               
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>COMPANY NAME </th>
                                <td>ITS</td>
                           
                            
                            </tr>
                           
                                <tr>
                                <th>DESIGNATION	</th>
                                <td>Full Stack Developer</td>
                            
                              
                            </tr>
                        </tbody>
                    </table>
                </div>
                        </div>
                    </div>
                </div>
                <div class="courses-box3 col-sm-12 col-lg-6  col-md-6 ">
                    <div class="single-item-wrapper category-digital">
                        <div class="courses-img-wrapper hvr-bounce-to-right">
                             <img src="assets/images/placement/placement2019/riyaparashar.png" alt="placement gallery" class="img-responsive center-block img-fluid">
                          
                        </div>
                        <div class="courses-content-wrapper">
                          
                          <div class="table-responsive">
                    <table class="table table-bordered table-responsive text-center text-uppercase text-bold" >
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <td>riya parashar</td>
                          
                               
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>COMPANY NAME </th>
                                <td>Airo</td>
                           
                            
                            </tr>
                           
                                <tr>
                                <th>DESIGNATION	</th>
                                <td>Full Stack Developer</td>
                            
                              
                            </tr>
                        </tbody>
                    </table>
                </div>
                        </div>
                    </div>
                </div>
        </div> 
        <div class="row">
                <div class="courses-box3 col-sm-12 col-lg-6  col-md-6 ">
                    <div class="single-item-wrapper category-digital">
                        <div class="courses-img-wrapper hvr-bounce-to-right">
                             <img src="assets/images/placement/placement2019/himanshukuswah.png" alt="placement gallery" class="img-responsive img-fluid center-block">
                          
                        </div>
                        <div class="courses-content-wrapper">
                          
                          <div class="table-responsive">
                    <table class="table table-bordered table-responsive text-center text-uppercase text-bold" >
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <td>himanshu kuswah</td>
                          
                               
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>COMPANY NAME </th>
                                <td>Expert Webtech</td>
                           
                            
                            </tr>
                           
                                <tr>
                                <th>DESIGNATION	</th>
                                <td>Full Stack Developer</td>
                            
                              
                            </tr>
                        </tbody>
                    </table>
                </div>
                        </div>
                    </div>
                </div>
                <div class="courses-box3 col-sm-12 col-lg-6  col-md-6 ">
                    <div class="single-item-wrapper category-digital">
                        <div class="courses-img-wrapper hvr-bounce-to-right">
                             <img src="assets/images/placement/placement2019/manoj.png" alt="placement gallery" class="img-responsive img-fluid center-block">
                          
                        </div>
                        <div class="courses-content-wrapper">
                          
                          <div class="table-responsive">
                    <table class="table table-bordered table-responsive text-center text-uppercase text-bold" >
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <td>Manoj</td>
                          
                               
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>COMPANY NAME </th>
                                <td>Mile high</td>
                           
                            
                            </tr>
                           
                                <tr>
                                <th>DESIGNATION	</th>
                                <td>Full Stack Developer</td>
                            
                              
                            </tr>
                        </tbody>
                    </table>
                </div>
                        </div>
                    </div>
                </div>
        </div>   
        <div class="row">
            <div class="courses-box3 col-sm-12 col-lg-6  col-md-6 ">
                <div class="single-item-wrapper category-digital">
                    <div class="courses-img-wrapper hvr-bounce-to-right">
                         <img src="assets/images/placement/placement2019/Deependra Singh Bhadoriya.png" alt="placement gallery" class="img-responsive img-fluid center-block">
                      
                    </div>
                    <div class="courses-content-wrapper">
                      
                      <div class="table-responsive">
                <table class="table table-bordered table-responsive text-center text-uppercase text-bold" >
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <td>Deependra Singh Bhadoriya</td>
                      
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>COMPANY NAME </th>
                            <td>java R&D Pvt.Ltd</td>
                       
                        
                        </tr>
                       
                            <tr>
                            <th>DESIGNATION	</th>
                            <td>Full Stack Developer</td>
                        
                          
                        </tr>
                    </tbody>
                </table>
            </div>
                    </div>
                </div>
            </div>
            <div class="courses-box3 col-sm-12 col-lg-6  col-md-6 ">
                <div class="single-item-wrapper category-digital">
                    <div class="courses-img-wrapper hvr-bounce-to-right">
                         <img src="assets/images/placement/placement2019/akanshatomar1.jpg" alt="placement gallery" class="img-responsive img-fluid center-block">
                      
                    </div>
                    <div class="courses-content-wrapper">
                      
                      <div class="table-responsive">
                <table class="table table-bordered table-responsive text-center text-uppercase text-bold" >
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <td>Akanksha Tomar</td>
                      
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>COMPANY NAME </th>
                            <td>Ignis it solution</td>
                       
                        
                        </tr>
                       
                            <tr>
                            <th>DESIGNATION	</th>
                            <td>Full Stack Developer</td>
                        
                          
                        </tr>
                    </tbody>
                </table>
            </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="courses-box3 col-sm-12 col-lg-6  col-md-6 ">
                <div class="single-item-wrapper category-analysis">
                    <div class="courses-img-wrapper hvr-bounce-to-right">
                         <img src="assets/images/placement/placement2018/akansha1.jpg" alt="placement gallery" class="img-responsive img-fluid center-block">
                      
                    </div>
                    <div class="courses-content-wrapper">
                      
                      <div class="table-responsive">
                <table class="table table-bordered table-responsive text-center text-uppercase text-bold" >
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <td>Akanksha Rajauria</td>
                      
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>COMPANY NAME </th>
                            <td>Apptech</td>
                       
                        
                        </tr>
                       
                            <tr>
                            <th>DESIGNATION	</th>
                            <td>Full Stack Developer</td>
                        
                          
                        </tr>
                    </tbody>
                </table>
            </div>
                    </div>
                </div>
            </div>
            <div class="courses-box3 col-sm-12 col-lg-6  col-md-6">
                <div class="single-item-wrapper category-analysis">
                    <div class="courses-img-wrapper hvr-bounce-to-right ">
                         <img src="assets/images/placement/placement2018/alok1.jpg" alt="placement gallery" class="img-responsive img-fluid center-block">
                      
                    </div>
                    <div class="courses-content-wrapper">
                      
                      <div class="table-responsive">
                <table class="table table-bordered table-responsive table-responsive text-center text-uppercase text-bold">
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <td>Alok Sharma</td>
                      
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>COMPANY NAME </th>
                            <td>Apptech</td>
                       
                        
                        </tr>
                        
                            <tr>
                            <th>DESIGNATION	</th>
                            <td>Full Stack Developer </td>
                        
                          
                        </tr>
                    </tbody>
                </table>
            </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="courses-box3 col-sm-12 col-lg-6  col-md-6">
                <div class="single-item-wrapper category-analysis">
                    <div class="courses-img-wrapper hvr-bounce-to-right">
                         <img src="assets/images/placement/placement2018/rohit.jpg" alt="placement gallery" class="img-responsive img-fluid center-block">
                      
                    </div>
                    <div class="courses-content-wrapper">
                      
                      <div class="table-responsive">
                <table class="table table-bordered table-responsive text-center text-uppercase text-bold" >
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <td>rohit rathore</td>
                      
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>COMPANY NAME </th>
                            <td>Mile High(Delhi)</td>
                       
                        
                        </tr>
                       
                            <tr>
                            <th>DESIGNATION	</th>
                            <td>Full Stack Developer</td>
                        
                          
                        </tr>
                    </tbody>
                </table>
            </div>
                    </div>
                </div>
            </div>
            <div class="courses-box3 col-sm-12 col-lg-6  col-md-6">
                <div class="single-item-wrapper category-analysis">
                    <div class="courses-img-wrapper hvr-bounce-to-right">
                         <img src="assets/images/placement/placement2018/vinod.jpg" alt="placement gallery" class="img-responsive img-fluid center-block">
                      
                    </div>
                    <div class="courses-content-wrapper">
                      
                      <div class="table-responsive">
                <table class="table table-bordered table-responsive text-center text-uppercase text-bold" >
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <td>Vinod Chauhan</td>
                      
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>COMPANY NAME </th>
                            <td>Mile high (DELHI)</td>
                       
                        
                        </tr>
                       
                            <tr>
                            <th>DESIGNATION	</th>
                            <td>Full Stack Developer</td>
                        
                          
                        </tr>
                    </tbody>
                </table>
            </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="courses-box3 col-sm-12 col-lg-6  col-md-6">
                <div class="single-item-wrapper category-analysis">
                    <div class="courses-img-wrapper hvr-bounce-to-right">
                         <img src="assets/images/placement/placement2018/vipinsharma1.jpg" alt="placement gallery" class="img-responsive img-fluid center-block">
                      
                    </div>
                    <div class="courses-content-wrapper">
                      
                      <div class="table-responsive">
                <table class="table table-bordered table-responsive text-center text-uppercase text-bold" >
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <td>Vipin sharma</td>
                      
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>COMPANY NAME </th>
                            <td>capgemini</td>
                       
                        
                        </tr>
                       
                            <tr>
                            <th>DESIGNATION	</th>
                            <td>Full Stack Developer</td>
                        
                          
                        </tr>
                    </tbody>
                </table>
            </div>
                    </div>
                </div>
            </div>
            <div class="courses-box3 col-sm-12 col-lg-6  col-md-6">
                <div class="single-item-wrapper category-analysis">
                    <div class="courses-img-wrapper hvr-bounce-to-right">
                         <img src="assets/images/placement/placement2018/vishal1.jpg" alt="placement gallery" class="img-responsive img-fluid center-block">
                      
                    </div>
                    <div class="courses-content-wrapper">
                      
                      <div class="table-responsive">
                <table class="table table-bordered table-responsive text-center text-uppercase text-bold" >
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <td>Vishal Gaur</td>
                      
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>COMPANY NAME </th>
                            <td>Infosys</td>
                       
                        
                        </tr>
                       
                            <tr>
                            <th>DESIGNATION	</th>
                            <td>Software engineer</td>
                        
                          
                        </tr>
                    </tbody>
                </table>
            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    </div>
