<!-- Features -->
<section class="features" style="margin-top: 45px;">
  <h4 class="lead text-center">PN INFOSYS is a leading global business consulting and IT service company,<br>Whether you need to run your business more efficiently or accelerate revenue growth, PN INFOSYS can get you there.</h4>
  <div class="container-fluid" style="width: 95%;">
    <div class="row">
      <div class="col-lg-3">
        <div class="feature-item">
          <div class="icon">
            <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Efeature%2Fcollaborative1.jpg?alt=media&token=b8ce06a7-a1c9-41e7-a4ed-3e65be127f11" alt="">
          </div>
          <h4>Collaborative<br> Spirit</h4>
          <p>We believe in developing true partnerships and making clients happy.</p>
        </div>
        
      </div>
      <div class="col-lg-3">
        <div class="feature-item">
          <div class="icon">
            <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Efeature%2Fthinking.png?alt=media&token=a4d7e55a-cc39-443b-bcbc-92921ae35f6a" alt="">
          </div>
          <h4>Expert<br> Thinking</h4>
          <p>We brings robust skill and forward looking perspectives to solve customer challenges.</p>
        </div>
        
      </div>
      <div class="col-lg-3">
        <div class="feature-item">
          <div class="icon">
            <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Efeature%2Fdedication.jpg?alt=media&token=2b6177d8-816a-45d8-acec-ca235232235e" alt="">
          </div>
          <h4>Exorbitant Dedication</h4>
          <p>PN Infosys is driven to meet client needs with determination and grit. We embrace tough challenges.</p>
        </div>
        
      </div>
      <div class="col-lg-3">
        <div class="feature-item">
          <div class="icon">
            <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Efeature%2Ftraining.png?alt=media&token=9f731de1-607f-4ce5-999b-3b5b940a78ae" alt="">
          </div>
          <h4>Industrial <br>Training</h4>
          <p>We provide free Industrial Internship to novice undergratuates. Basically our aim is to help students.</p>
        </div>
        
      </div>
    </div>
  </div>
</section>
