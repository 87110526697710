<!-- Good Tips -->
<section class="good-tips">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 align-self-center">
        <div class="section-heading">
          <!-- <h6>Tips For You!</h6> -->
          <h2>Learning environment,Free
            Internship to novice students.</h2>
        </div>
        <div class="tips-content">
          <ul>
            <li>
              <div class="icon">
                <i class="fa fa-laptop" style="font-size: 47px;
                  color: red;"></i>
              </div>
              <div class="right-content">
                <h4>WEB DESIGNING</h4>
                <p>Something which makes PN INFOSYS different from other IT companies is that we train novice students and also make them work on Live projects.</p>
              </div>
            </li>
            <li>
              <div class="icon">
                <i class="fa fa-laptop" style="font-size: 47px;
                  color: red;"></i>
              </div>
              <div class="right-content">
                <h4>WEB DEVELOPMENT</h4>
                <p>Something which makes PN INFOSYS different from other IT companies is that we train novice students and also make them work on Live projects.</p>
              </div>
            </li>
            <li>
              <div class="icon">
                <i class="fa fa-mobile" style="font-size: 47px;
                  color: red;"></i>
              </div>
              <div class="right-content">
                <h4>APP DEVELOPMENT</h4>
                <p>Something which makes PN INFOSYS different from other IT companies is that we train novice students and also make them work on Live projects.</p>
              </div>
            </li>
            <li>
              <div class="icon">
                <!-- <img src="assets/frontend/images/features-icon-03.png" alt=""> -->
                <i class="fa fa-laptop" style="font-size: 47px;
                  color: red;"></i>
              </div>
              <div class="right-content">
                <h4>ANGULAR, PYTHON, DJANGO, LARAVEL</h4>
                <p>Something which makes PN INFOSYS different from other IT companies is that we train novice students and also make them work on Live projects.</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-6 align-self-center">
        <div class="right-image">
          <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Etips%2Flearning.png?alt=media&token=ce9f919b-595f-44e0-bbbd-aa72aba5b8f6" alt="">
        </div>
      </div>
    </div>
  </div>
</section>