<!-- Our Client -->
<section class="blog-page" style="margin-top: 0; margin-bottom: 0;" >
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="section-heading">
          <h3 style="color: #009bf2;" >Our</h3>
          <h1>Clients</h1>
          <p class="col-md-12" style="font-size: 106%;
                    color: #716f85;
                    line-height: 26px;
                    margin: 0px;
                    font-weight: 400;
                    text-align: justify;">PN INFOSYS is driven to meet client needs with determination and grit. We embrace tough challenges and do not rest until the problem is solved, the right way.</p>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="blog-posts blog-posts-second-version">
          <div class="rkm">
          <div class="row">
            <div class="col-lg-4">
              <div class="blog-post">
                <div class="blog-thumb">
                  <a href="#"><img src="assets/frontend/images/client/vee.jpg" alt=""></a>
                </div>
                <!-- <div class="down-content">
                  <h4>15 SEO Best Practices: Website Architecture</h4>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4">
              <div class="blog-post">
                <div class="blog-thumb">
                  <a href="#"><img src="assets/frontend/images/client/IDTR.png" alt=""></a>
                </div>
                <!-- <div class="down-content">
                  <h4>15 SEO Best Practices: Website Architecture</h4>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4">
              <div class="blog-post">
                <div class="blog-thumb">
                  <a href="#"><img src="assets/frontend/images/client/kgc.jpg" alt=""></a>
                </div>
                <!-- <div class="down-content">
                  <h4>15 SEO Best Practices: Website Architecture</h4>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4">
              <div class="blog-post">
                <div class="blog-thumb">
                  <a href="#"><img src="assets/frontend/images/client/temple.jpg" alt=""></a>
                </div>
                <!-- <div class="down-content">
                  <h4>15 SEO Best Practices: Website Architecture</h4>
                </div> -->
              </div>
            </div>
            <div class="col-lg-4">
              <!-- <div class="blog-post">
                <div class="blog-thumb">
                  <a href="#"><img src="assets/frontend/images/client/handmadelovebytanya.jpg" alt=""></a>
                </div>
                <div class="down-content">
                  <h4>15 SEO Best Practices: Website Architecture</h4>
                </div> 
              </div> -->
            </div>
            <div class="col-lg-4">
              <div class="blog-post">
                <div class="blog-thumb">
                  <a href="#"><img src="assets/frontend/images/client/rishiclient.jpg" alt=""></a>
                </div>
                <!-- <div class="down-content">
                  <h4>15 SEO Best Practices: Website Architecture</h4>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</section>