
      <nav class="navbar navbar-expand-sm bg-light navbar-dark">
      <a class="navbar-brand" routerLink="">
        <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/logo%2Fcolorlogo.png?alt=media&token=0386f0aa-e1e1-4950-924f-3eedaa82d967">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
        <span class="navbar-toggler-icon bg-primary"></span>
      </button>
      <div class="collapse navbar-collapse" id="collapsibleNavbar">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item"><a class="nav-link" [routerLink]="['']">Home</a></li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['about']">AboutUs</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['service']">Service</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['training']">Training</a>
          </li>
         
           <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
             Workshop
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item text-center" routerLink='/workshop/xiaomi' RouterLink="Activate">Xiaomi MI Company</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item  text-center" routerLink='/workshop/bentchair' RouterLink="Activate">Bentchair Company</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item text-center" routerLink='/workshop/rjit' RouterLink="Activate">Rjit College</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item text-center" routerLink='/workshop/mpct' RouterLink="Activate">Mpct College</a>
             
             
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Events
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item text-center" routerLink="/event/birthday">Student's Birthday</a>
              <div class="dropdown-divider"></div>
            <a class="dropdown-item text-center" routerLink="/event/aniversary">Aniversary Celebration</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item text-center" routerLink="/event/tour">Tour's</a>
             
             
            </div>
          </li>
           <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Placement
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item text-center" routerLink="/placement">Placement Desk</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item text-center" routerLink="/placement_gallery">Placement Gallery</a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['\contact']">ContactUs</a>
          </li> 
          
          <li class="nav-item"> 
           <a class="nav-link" target="_blank" href="http://internship2020.pneducation.website/" >InternshipRegistration</a> 
          </li>   
        </ul>
      </div>  
    </nav>  




