
<!-- Steps -->
<div class="main-content">
      
  <!-- Page Heading -->
  <div class="page-heading">
    <div class="container"><br><br><br><br><br>
      <div class="row">
        <div class="col-lg-12">
          <br>
          <br>
          
         
        </div>
      </div>
    </div>
  </div>
 <!-- Steps -->
 <section class="steps">
  <div class="section-heading">
    <h1 style="color: #009df2;font-size: xxx-large;text-align: center;"> Welcome to our Placement Cell </h1>
    <h4 class="align-justify" style="font-size: xx-large;text-align: center;">We are here for your bright future.</h4>
    
  </div>
    <br>
  <div class="container">
    
    <div class="row">
      <div class="col-lg-4">
        <div class="step-item">
          <div class="item-number">
            <h6>01</h6>
          </div>
          <div class="item-content">
            <h4>First Step</h4>
            <p>Come to us...!!</p>
          </div>
          <div class="item-arrow">
            <i class="fa fa-angle-right"></i>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="step-item">
          <div class="item-number">
            <h6>02</h6>
          </div>
          <div class="item-content">
            <h4>Second Step</h4>
            <p>Learn with us..!!</p>
          </div>
          <div class="item-arrow">
            <i class="fa fa-angle-right"></i>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="step-item">
          <div class="item-number">
            <h6>03</h6>
          </div>
          <div class="item-content">
            <h4>Third Step</h4>
            <p>Be a <span>JOB SEEKER</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    <!-- About Tips -->
    <section class="about-tips our-team-page">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 align-self-center">
              <div class="video-thumb">
               
                <img src="assets/images/placement/placementdesk/vj1.png" alt="">
              </div>
            </div>
            <div class="col-lg-6">
              <div class="section-heading">
                <h6>Message From</h6>
                <h2>Chairman's Desk</h2>
                
              </div>
              <p class="text-justify message">Established in the year of 2018 .Pninfosys is the  software company rapidly growing in last 2 year in Gwalior. Our company is not only a training centre as well it is a product based company
                Pninfosys which was a dream few year ago has become a reality and much sought after institutions in and around Gwalior.
                <br><br>It gives me a great pleasure to  informing U
                to batch of 2018,19 are full placed
                All students reached in high paid company
                In Delhi, indore,Bhopal
                I m happy to have the opportunity serve in such a magnificent and a look forward to helping pav the way for a bright and successful future.</p>
              
            </div>
          </div>
        </div>
      </section>
 
      <section class="about-tips our-team-page">
        <div class="container">
          <div class="row">
           
            <div class="col-lg-6">
              <div class="section-heading">
                <h6>Message From</h6>
                <h2>CEO Desk</h2>
                
              </div>
              <p  class="text-justify message">When educating the minds of our youth we must not forget to educate their hearts. 
                We focus on discovering developing and drawing out the hidden talent and the magic lying
                
                Dormant inside all of it's student
                Our future goal in clear.<br><br>
                 We gives good quality
                training for our students in next 5year.
                Today dynamic world 360  degree development
                And grooming is of supreme important and through our company.
                We are creating an environment for future 
                leaders , entrepreneurs and professional who posses skill and aptitude in añ array of functional Discipline.</p>
              
            </div>
            <div class="col-lg-6 align-self-center">
              <div class="video-thumb">
                <!-- <a href="http://youtube.com"><i class="fa fa-play"></i></a> -->
                <img src="assets/images/placement/placementdesk/neha (1).jpeg" alt="">
              </div>
            </div>
          </div>
        </div>
      </section>

    <!-- students Love -->
 
  <app-testimonial></app-testimonial>  
  </div>