import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skil',
  templateUrl: './skil.component.html',
  styleUrls: ['./skil.component.css']
})
export class SkilComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
