
  <div class="main-content">
  
    <!-- Main Banner -->
    <div class="parallax-banner">
      <!--Content before waves-->
      <div class="inner-header">
        <div class="inner-content">
          <h4 style="text-shadow: 0px 0px 5px #000000;">what are you waiting for?</h4>
          <!-- <h1>Social Network Marketing<br>&amp; SEO Agency</h1> -->
          <h1 style="text-shadow: 0px 0px 5px #000000;">Our PN Infosys<br> We're ready to help you grow!.</h1>
         
          <div class="main-decoration">
            <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Ebanner%2Fbanner2.png?alt=media&token=ca3ffc74-514c-4d79-9af4-c025e88b89aa" alt="" style="width: 50%;">
          </div>
        </div>
      </div>

      <!--Waves Container-->
      <div>
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g class="parallax">
        <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
        <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
        </svg>
      </div>
      <!--Waves end-->
    </div>