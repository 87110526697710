

<div class="page-heading">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h1>aniversary Celebration</h1>
          <span><a routerLink="/none">Home</a>Celebration</span>
        </div>
      </div>
    </div>
  </div>

  
  <!-- Recent Cases -->
  <section class="recent-cases portfolio-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-heading">
            <h6 class="text-center">Pn Infosys Celebration</h6>
            <h2 class="text-center">Company Celebration</h2>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="row masonry-layout filters-content normal-col-gap">
            <div class="col-lg-4 masonry-item all category-analysis">
              <div class="case-item">
                <a href="assets/frontend/images/event/aniversary/a1.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/aniversary/a1.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/aniversary/a2.png" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/aniversary/a2.png" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-digital">
              <div class="case-item">
                <a href="assets/frontend/images/event/aniversary/a3.png" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/aniversary/a3.png" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-digital">
              <div class="case-item">
                <a href="assets/frontend/images/event/aniversary/a4.png" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/aniversary/a4.png" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-analysis">
              <div class="case-item">
                <a href="assets/frontend/images/event/aniversary/a5.png" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/aniversary/a5.png" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/aniversary/a6.png" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/aniversary/a6.png" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/aniversary/a7.png" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/aniversary/a7.png" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/aniversary/a8.png" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/aniversary/a8.png" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/aniversary/a9.png" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/aniversary/a9.png" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
  </section>
