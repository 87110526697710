<div style="text-align:center">
    <h1>
     Our Clients
    </h1>
    <!-- <div>Some tags before</div> -->
      <owl-carousel-o [options]="customOptions">
      <ng-template class="m-1" carouselSlide><img  src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2Frowslider%2Fvee.jpg?alt=media&token=617847a8-22a9-4ee7-b454-c067669efe4f"></ng-template>  
      <ng-template class="m-1" carouselSlide><img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2Frowslider%2FIDTR.png?alt=media&token=7b350447-b83c-44fa-af67-3ed92c981457"></ng-template>  
      <ng-template class="m-1 ml-1" carouselSlide><img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2Frowslider%2Ftemple.jpg?alt=media&token=579af0a8-dd0d-4b73-ad5c-a41e4e6c8e91"></ng-template> 
      <ng-template class="m-1" carouselSlide><img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2Frowslider%2Frjn.jpg?alt=media&token=2f1b02c6-442a-4461-888c-300041b961bf"></ng-template>  
      <ng-template class="m-1" carouselSlide><img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/about%2Frowslider%2Frjnpic.jpg?alt=media&token=f4a7b2f8-35db-460c-8305-f0cd8d165d40"></ng-template>  
    </owl-carousel-o>
    <!-- <div>Some tags after</div> -->
  </div>
