  <!-- Good Tips -->
  <section class="good-tips">
    <div class="container">
      <div class="row">
        <div class="col-md-12"><h1 style="text-align: center; color:white ;">Our Services</h1></div>
        
        <div class="col-lg-6 align-self-center">
          <div class="section-heading">
           
            
          </div>
          <div class="tips-content">
            <ul>
              <li>
                <div class="icon">
                  <!-- <img src="images/features-icon-01.png" alt=""> -->
                  <i class="fa fa-lightbulb-o"></i>
                </div>
                <div class="right-content">
                  <h4>INNOVATIVE
                    Ideas</h4>
                  <p class="align-justify">PN INFOSYS believes in developing true partnerships. We foster a collegial environment where individual perspectives are respected and honest dialogue is expected.</p>
                </div>
              </li> 
              <li>
                <div class="icon">
                  <i class="fa fa-server" ></i>
                </div>
                <div class="right-content">
                  <h4>CREATIVE
                    Designing</h4>
                  <p class="align-justify">PN INFOSYS brings robust skills and forward looking perspectives to solve customer challenges. We use proven knowledge to make recommendations and provide expert guidance to our customers.</p>
                </div>
              </li>
              <li>
                <div class="icon">
                  <i class="fa fa-users"></i>
                </div>
                <div class="right-content">
                  <h4>CLIENT'S
                    Happiness</h4>
                  <p class="align-justify">PN INFOSYS is driven to meet client needs with determination and grit. We embrace tough challenges and do not rest until the problem is solved, the right way.</p>
                </div>
              </li>
              <li>
                <div class="icon">
                  <i class="fa fa-tasks"></i>
                </div>
                <div class="right-content">
                  <h4>FULL
                    Maintenance</h4>
                  <p class="text-justify text-align">PN INFOSYS Company provides a full range of maintenance and compliance services for Government and Commercial facilities both large and small.</p>
                </div>
              </li>
              <li>
                <div class="icon">
                  <i class="fa fa-bullseye"></i>
                </div>
                <div class="right-content">
                  <h4>PRACTICAL
                    Training</h4>
                  <p class="align-justify">We don't use paper and pencil at all in our training sessions.We are provided only practical work at training class session.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 align-self-center">
          <div class="right-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Eservices%2Fe-learning.png?alt=media&token=71eaebcc-1041-4891-bb3d-68cc30b0173b" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
