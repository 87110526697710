
<div class="main-content">
      
    <!-- Page Heading -->
    <div class="page-heading">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1>Placement Details</h1>
            <span><a href="index.html">Home</a>Placement Details</span>
          </div>
        </div>
      </div>
    </div>
    <fieldset><legend >Placement Details</legend>
    <div class=" table-responsive">
	 
        <table width="100%" class="table
       " style="margin-top:12px;"><thead>
                     <tr>
                     <th>S No</th>
                     <th>Batch</th>
                     <th>Batch Size</th>
                     <th>No. of Students Placed</th>
                     
                     <th>Highest Package Offered</th>
                     
                     <th>Action</th>
                     </tr>
                 </thead><tbody><tr>
                   <td>1.</td>
                   <td>2018-20</td>
                   <td>277</td>
                   <td>258</td>
                   
                   <td>12.00 LPA</td>
                  
                   <td><a href="placement_list.php?cId=2&amp;bID=31" class="view-all-accent-btn ">View Details</a></td>
                 </tr><tr>
                   <td>2.</td>
                   <td>2017-19</td>
                   <td>287</td>
                   <td>280</td>
                   
                   <td>10.34 LPA</td>
                   
                   <td><a href="placement_list.php?cId=2&amp;bID=26" class="view-all-accent-btn">View Details</a></td>
                 </tr><tr>
                   <td>3.</td>
                   <td>2016-18</td>
                   <td>259</td>
                   <td>247</td>
                   
                   <td>10.0 LPA</td>
                   
                   <td><a href="placement_list.php?cId=2&amp;bID=1" class="view-all-accent-btn">View Details</a></td>
                </tr>
                </tbody>
            </table>
        </div>
    </fieldset>
</div>