<!-- Section: Testimonials v.4 -->
<section class="container text-center my-5 mt-5">
  <!-- Section heading -->
  <h2 class="h1-responsive font-weight-bold my-5">What our students say..!!</h2>
  <!-- Grid row -->
  <div class="row">
    <!--Carousel Wrapper-->
    <mdb-carousel [isControls]="false" class="carousel-multi-item multi-animation testimonial-carousel" [type]="'carousel-multi-item'"
      [animation]="'slide'">
      <!--First slide-->
      <mdb-slide>
        <!--Grid column-->
        <div class="col-md-4">
          <div class="testimonial">
            <!--Avatar-->
            <!-- <div class="avatar mx-auto">
              <img src="assets/images/placement/placementdesk/testimonial/manoj.png" class="rounded-circle img-fluid">
            </div> -->
            <!--Content-->
            <h4 class="font-weight-bold mt-4">Manoj Palwaliya</h4>
            <h6 class="blue-text font-weight-bold my-3">Full Stack Developer</h6>
            <p class="font-weight-normal">
              <mdb-icon fa icon="quote-left" class="fa fa-quote-left pr-2"></mdb-icon>
              It is the best learning hub for all adamant students.
              It is based on 100% practical approach.
              Here trainee gets to work on Live Projects, which automatically strengthens the portfolio(C.V).

              <mdb-icon fa icon="quote-right" class="fa fa-quote-right pr-2"></mdb-icon>
            </p>
            <!--Review-->
            
          </div>
        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div class="col-md-4 clearfix d-none d-md-block">
          <div class="testimonial">
            <!--Avatar-->
            <!-- <div class="avatar mx-auto">
              <img src="assets/images/placement/placementdesk/testimonial/riyaparashar.png" class="rounded-circle img-fluid">
            </div> -->
            <!--Content-->
            <h4 class="font-weight-bold mt-4">Riya Parashar </h4>
            <h6 class="blue-text font-weight-bold my-3">Web Developer</h6>
            <p class="font-weight-normal">
              <mdb-icon fa icon="quote-left" class="fa fa-quote-left pr-2"></mdb-icon>
              It is the best learning hub for all students. Best place to learn ..thank u PN Infosys ♥️
              <mdb-icon fa icon="quote-right" class="fa fa-quote-right pr-2"></mdb-icon>
            </p>
          
          </div>
        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div class="col-md-4 clearfix d-none d-md-block">
          <div class="testimonial">
            <!--Avatar-->
            <!-- <div class="avatar mx-auto">
              <img src="assets/images/placement/placementdesk/testimonial/akanshatomar.png" class="rounded-circle img-fluid">
            </div> -->
            <!--Content-->
            <h4 class="font-weight-bold mt-4">Akansha Tomar</h4>
            <h6 class="blue-text font-weight-bold my-3">Full-Stack Developer</h6>
            <p class="font-weight-normal">
              <mdb-icon fa icon="quote-left" class="fa fa-quote-left pr-2"></mdb-icon>
              Best place  to learn, grow and to nourish up technical  skills, focus on web designing and web development, provide complete training from basic to advance level.Work on latest technology which are in demand.
              <mdb-icon fa icon="quote-right" class="fa fa-quote-right pr-2"></mdb-icon>
            </p>
           
          </div>
        </div>
        <!--Grid column-->
      </mdb-slide>
      <!--/First slide-->
      <!--Second slide-->
      <mdb-slide>
        <div class="col-md-4">
          <div class="testimonial">
            <!--Avatar-->
            <!-- <div class="avatar mx-auto">
              <img src="assets/images/placement/placementdesk/testimonial/rohitkumar.png" class="rounded-circle img-fluid">
            </div> -->
            <!--Content-->
            <h4 class="font-weight-bold mt-4">Rohit Kumar</h4>
            <h6 class="blue-text font-weight-bold my-3">UI/UX Developer</h6>
            <p class="font-weight-normal">
              <mdb-icon fa icon="quote-left" class="fa fa-quote-left pr-2"></mdb-icon>
              Its a great experience in PN INFOSYS, the working environment which they provides to their employees is just incredible.
              Thanks for everything from bottom of my heart ♥️
              <mdb-icon fa icon="quote-right" class="fa fa-quote-right pr-2"></mdb-icon>
            </p>
           
            
          </div>
        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div class="col-md-4 clearfix d-none d-md-block">
          <div class="testimonial">
            <!--Avatar-->
            <!-- <div class="avatar mx-auto">
              <img src="assets/images/placement/placementdesk/testimonial/vinod.png" class="rounded-circle img-fluid">
            </div> -->
            <!--Content-->
            <h4 class="font-weight-bold mt-4">Vinod Chauhan</h4>
            <h6 class="blue-text font-weight-bold my-3">Full-Stack Developer</h6>
            <p class="font-weight-normal">
              <mdb-icon fa icon="quote-left" class="fa fa-quote-left pr-2"></mdb-icon>
              I have been interning here since, June 15/2018. 
              And my Experience with the team here is quite interesting, following are the points that i would like to enlist:-
             * Excellent & knowledgeable teaching staff.
             * Availability of learning resources.
             * Hands on latest technologies.
             * 8:2 split rule i.e.  80% practical & 20%theory which is pretty good.
             * And on the top, we enjoy a lot here while exploring new things.... 😉
              <mdb-icon fa icon="quote-right" class="fa fa-quote-right pr-2"></mdb-icon>
            </p>
           
          </div>
        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div class="col-md-4 clearfix d-none d-md-block">
          <div class="testimonial">
            <!--Avatar-->
            <!-- <div class="avatar mx-auto">
              <img src="assets/images/placement/placementdesk/testimonial/yash.png" class="rounded-circle img-fluid">
            </div> -->
            <!--Content-->
            <h4 class="font-weight-bold mt-4">Vipin Sharnma</h4>
            <h6 class="blue-text font-weight-bold my-3">Developer</h6>
            <p class="font-weight-normal">
              <mdb-icon fa icon="quote-left" class="fa fa-quote-left pr-2"></mdb-icon>
               This is the platform where we can learn many things, not even web designing or web development, we can learn how to represent yourself and many more things. 
               Here all classes are practically conducting which is the best way of teaching to grasp more knowledge by students in trainings.
              <mdb-icon fa icon="quote-right" class="fa fa-quote-right pr-2"></mdb-icon>
            </p>
           
          </div>
        </div>
        <!--Grid column-->
      </mdb-slide>
      <!--/Second slide-->
      <!--Third slide-->
      <mdb-slide>
        <!--Grid column-->
        <div class="col-md-4">
          <div class="testimonial">
            <!--Avatar-->
            <!-- <div class="avatar mx-auto">
              <img src="assets/images/placement/placementdesk/testimonial/alok.png" class="rounded-circle img-fluid">
            </div> -->
            <!--Content-->
            <h4 class="font-weight-bold mt-4">Alok Saxena</h4>
            <h6 class="blue-text font-weight-bold my-3">Full-Stack Developer</h6>
            <p class="font-weight-normal">
              <mdb-icon fa icon="quote-left" class="fa fa-quote-left pr-2"></mdb-icon>
              Very friendly.. They respond to queries immediately and they extend help hot to resolve some concerns and his way of teaching is awsm..
              <mdb-icon fa icon="quote-right" class="fa fa-quote-right pr-2"></mdb-icon>
            </p>
          </div>
        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div class="col-md-4 clearfix d-none d-md-block">
          <div class="testimonial">
            <!--Avatar-->
            <!-- <div class="avatar mx-auto">
              <img src="assets/images/placement/placementdesk/testimonial/rekha.png" class="rounded-circle img-fluid">
            </div> -->
            <!--Content-->
            <h4 class="font-weight-bold mt-4">Rekha</h4>
            <h6 class="blue-text font-weight-bold my-3">Full-Stack Developer</h6>
            <p class="font-weight-normal">
              <mdb-icon fa icon="quote-left" class="fa fa-quote-left pr-2"></mdb-icon>
              Amazing 
              Way of teaching is too good .
              Complete concepts in easy way to learn.
              Highly recommendable....
              <mdb-icon fa icon="quote-right" class="fa fa-quote-right pr-2"></mdb-icon>
            </p>
           
          </div>
        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div class="col-md-4 clearfix d-none d-md-block">
          <div class="testimonial">
            <!--Avatar-->
            <!-- <div class="avatar mx-auto">
              <img src="assets/images/placement/placementdesk/testimonial/chanchal.png" class="rounded-circle img-fluid">
            </div> -->
            <!--Content-->
            <h4 class="font-weight-bold mt-4">Srashti Tripathi 'Safeena'</h4>
            <h6 class="blue-text font-weight-bold my-3">Full-Stack Developer</h6>
            <p class="font-weight-normal">
              <mdb-icon fa icon="quote-left" class="fa fa-quote-left pr-2"></mdb-icon>
              I worked  part-time there from 2019, Amazing culture, great benefits, great pay, good opportunity to learn and awesome people and management.
              Mentor is very nice and supportive.
              <mdb-icon fa icon="quote-right" class="fa fa-quote-right pr-2"></mdb-icon>
            </p>
           
          </div>
        </div>
        <!--Grid column-->
      </mdb-slide>
      <!--/Third slide-->
    </mdb-carousel>
    <!--/.Carousel Wrapper-->
  </div>
  <!-- Grid row -->
</section>
<!-- Section: Testimonials v.4 -->










