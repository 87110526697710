 <!-- Preloader -->
 <div id="js-preloader" class="js-preloader">
    <div class="content">
      <img src="assets/frontend/images/pn_logo.png" alt="">
    </div>
    <div class="preloader-inner">
    </div>
  </div>
  
  
   