
<div class="main-content">
      
    <!-- Page Heading -->
    <div class="page-heading">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1>Say Hello To Us!</h1>
            <span><a href="index.html">Home</a>Contact Us</span>
          </div>
        </div>
      </div>
    </div>

    
    <!-- Contact Info -->
    <section class="contact-info">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="info-item">
              <div class="icon">
                <i class="fa fa-envelope"></i>
              </div>
              <h4>Email Address</h4>
              <p><a href="#">www.pninfosys.com</a><br><a href="#">support@pninfosys.com</a></p>
              <p style="visibility: hidden;">kuch nahi</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="info-item">
              <div class="icon">
                <i class="fa fa-phone"></i>
              </div>
              <h4>Phone Number</h4>
              <p><a href="#">+91 7000846823</a><br><a href="#">+91 7415289378</a></p>
              <p style="visibility: hidden;">kuch nahi</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="info-item">
              <div class="icon">
                <i class="fa fa-map-marker"></i>
              </div>
              <h4>Street Address</h4>
              <p><a href="#">MIG-332<br>Darpan Colony,Thatipur,<br>Gwalior,Madhya Pradesh</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>


    <!-- Contact Us -->
    <section class="contact-us">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="inner-content">
              <div class="block-heading">
                <h4>Say Hello To Us!</h4>
                <div *ngIf="alert"  class="alert alert-success alert-dismissible fade show" role="alert">
                  <strong>Success!</strong>Your Data has been entered.
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>
              </div>
              <form [formGroup]="contactForm" (ngSubmit)="collectData()">
                <div class="row">
                  <div class="col-lg-4 col-md-12 col-sm-12">
                    <input name="name" type="text" formControlName="name" class="form-control" id="name" placeholder="Full Name" required="">
                  </div>
                  <div class="col-lg-8 col-md-12 col-sm-12">
                    <input name="email" type="text" formControlName="email" class="form-control" id="email" pattern="[^ @]*@[^ @]*" placeholder="E-Mail Address" required="">
                  </div>
                  
                  <div class="col-lg-12">
                    <textarea name="message" formControlName="message" rows="6" class="form-control" id="your-message" placeholder="Your Message" required=""></textarea>
                  </div>
                  <div class="col-lg-12">
                    <button type="submit" id="form-submit" class="filled-button">Send Message Now</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="inner-content">
              <div class="block-heading">
                <h4>More Info</h4>
              </div>
              <img src="assets/frontend/images/colorlogo.png" style="width: 239px;">
              
                <div class="row">
                  <div class="col-lg-12">
                    <p style="font-size: 15px; text-align: justify; ">PN INFOSYS provides the best service possible to its customers because for us, our client’s happiness is important. Whatever we choose to do, we do it an exorbitant manner. PN INFOSYS Company provides a full range of maintenance and compliance services for Government and Commercial facilities both large and small.</p>
                  </div>
                  
                  <div class="col-lg-12">
                    <br>
                    
                    <button type="submit" id="form-submit" class="filled-button">Read More..</button>
                  </div>
                </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>


    <!-- Find Us On Map -->
    <section class="map">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner-content">
              <div class="block-heading">
                <h4>Find Us On Map</h4>
              </div>
              <div id="map">
                <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23481.794658246406!2d21.09932224141022!3d42.635403966818785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549de02b95feb3%3A0x40df47fa2a0e9612!2sPrishtina!5e0!3m2!1sen!2s!4v1504188040281" style="border:0; width: 100%; height: 400px; border: none; border-radius: 10px;" allowfullscreen></iframe> -->
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3579.6287734169678!2d78.20696011434966!3d26.208751696349776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3976c3a3faabd5e3%3A0x88d563b9d79500ed!2sPN%20INFOSYS!5e0!3m2!1sen!2sin!4v1594718912596!5m2!1sen!2sin" style="border:0; width: 100%; height: 400px; border: none; border-radius: 10px;" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


  </div>