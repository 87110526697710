<section class="colorlib-skills container" data-section="skills" style="margin-top: 50px;
margin-bottom: 50px;">
  <div class="colorlib-narrow-content">
    <div class="row">
      <div class="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
        <span class="heading-meta" style="color:#009bf2">Our Specialty</span>
        <h2 class="colorlib-heading animate-box">Our Skills</h2>
      </div>
    </div>
    <div class="row">
      <div class="animate-box container col-md-11" data-animate-effect="fadeInLeft">
        <p>Basically through these training sessions, we want to help students to grow, Our training sessions are helping hands for adamant students.</p>
        <br>
      </div>
      <div class="rk_skill container">
        <div class="row">
        <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
          <div class="progress-wrap">
            <h3>HTML5</h3>
            <div class="progress">
               <div class="progress-bar color-3" role="progressbar" aria-valuenow="95"
                aria-valuemin="0" aria-valuemax="100" style="width:95%">
                <span>95%</span>
                </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
          <div class="progress-wrap">
            <h3>JavaScript</h3>
            <div class="progress">
               <div class="progress-bar color-1" role="progressbar" aria-valuenow="87"
                aria-valuemin="0" aria-valuemax="100" style="width:87%">
                <span>87%</span>
                </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 animate-box" data-animate-effect="fadeInRight">
          <div class="progress-wrap">
            <h3>CSS3</h3>
            <div class="progress">
               <div class="progress-bar color-4" role="progressbar" aria-valuenow="86"
                aria-valuemin="0" aria-valuemax="100" style="width:86%">
                <span>86%</span>
                </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 animate-box" data-animate-effect="fadeInRight">
          <div class="progress-wrap">
            <h3>MySql</h3>
            <div class="progress">
               <div class="progress-bar color-2" role="progressbar" aria-valuenow="94"
                aria-valuemin="0" aria-valuemax="100" style="width:94%">
                <span>94%</span>
                </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft">
          <div class="progress-wrap">
            <h3>PHP</h3>
            <div class="progress">
               <div class="progress-bar color-5" role="progressbar" aria-valuenow="90"
                aria-valuemin="0" aria-valuemax="100" style="width:90%">
                <span>90%</span>
                </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 animate-box" data-animate-effect="fadeInRight">
          <div class="progress-wrap">
            <h3>Laravel</h3>
            <div class="progress">
               <div class="progress-bar color-6" role="progressbar" aria-valuenow="80"
                aria-valuemin="0" aria-valuemax="100" style="width:80%">
                <span>80%</span>
                </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 animate-box" data-animate-effect="fadeInRight">
          <div class="progress-wrap">
            <h3>React.JS</h3>
            <div class="progress">
               <div class="progress-bar color-7" role="progressbar" aria-valuenow="79"
                aria-valuemin="0" aria-valuemax="100" style="width:79%">
                <span>79%</span>
                </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 animate-box" data-animate-effect="fadeInRight">
          <div class="progress-wrap">
            <h3>Angular</h3>
            <div class="progress">
               <div class="progress-bar color-8" role="progressbar" aria-valuenow="87"
                aria-valuemin="0" aria-valuemax="100" style="width:87%">
                <span>87%</span>
                </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</section>
