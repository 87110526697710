
<div class="page-heading">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h1>Birthday Celebration</h1>
          <span><a href="index.html">Home</a>Pninfosys</span>
        </div>
      </div>
    </div>
  </div>

  
  <!-- Recent Cases -->
  <section class="recent-cases portfolio-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-heading">
            <h6 class="text-center">Birthday Celebration</h6>
            <h2 class="text-center">Pninfosys Team</h2>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="row masonry-layout filters-content normal-col-gap">
            <div class="col-lg-4 masonry-item all category-analysis">
              <div class="case-item">
                <a href="assets/frontend/images/event/birthday/hbd1.jpg">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/birthday/hbd1.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/birthday/hbd19.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/birthday/hbd2.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-digital">
              <div class="case-item">
                <a href="assets/frontend/images/event/birthday/hbd3.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/birthday/hbd3.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-digital">
              <div class="case-item">
                <a href="assets/frontend/images/event/birthday/hbd4.png" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/birthday/hbd4.png" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-analysis">
              <div class="case-item">
                <a href="assets/frontend/images/event/birthday/hbd5.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/birthday/hbd5.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/birthday/hbd6.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/birthday/hbd6.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/birthday/hbd7.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/birthday/hbd7.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/birthday/hbd8.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/birthday/hbd8.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/birthday/hbd9.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/birthday/hbd9.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/birthday/hbd10.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/birthday/hbd10.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/birthday/hbd11.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/birthday/hbd11.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/birthday/hbd12.png" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/birthday/hbd12.png" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/birthday/hbd13.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/birthday/hbd13.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/birthday/hbd4.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/birthday/hbd14.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/birthday/hbd15.jpg" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/birthday/hbd15.jpg" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
            <div class="col-lg-4 masonry-item all category-seo">
              <div class="case-item">
                <a href="assets/frontend/images/event/birthday/hbd16.png" class="zoom" target="">
                <div class="case-thumb">
                  <img src="assets/frontend/images/event/birthday/hbd16.png" alt="" style="margin-top: 0px;">
                </div>
                </a>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
  </section>
