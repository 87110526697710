<!-- Recent Cases -->
<section class="portfolio-page-second-version">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="section-heading">
          <h4 style="font-size: xx-large;">Our</h4>
          <h1 style="color: #009df2;font-size: xxx-large;">Portfolio</h1>
        </div>
      </div>
      
      <div class="col-lg-12">
        <div class="row masonry-layout filters-content normal-col-gap">
          <div class="col-lg-4 masonry-item all category-analysis">
            <div class="case-item">
              <div class="case-thumb">
                <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Eportfolio%2Fidtrnew.png?alt=media&token=724ae9b3-fc39-4e1d-9def-8f717da38d66" style="width: 76%; height: 76%;" class="img-responsive mx-auto" alt="">
                <div class="hover-effect">
                  <div class="hover-content">
                    <a routerLink="/none"><i class="fa fa-search"></i></a>
                    <a href="http://idtrgwl.com/" ><i class="fa fa-link"></i></a>
                    <h3 style="color: white;">Institute of Drivers Training and Research</h3>
                    <h5 style="color: white;">Gwalior</h5>
                  
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 masonry-item all category-seo">
            <div class="case-item">
              <div class="case-thumb">
                <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Eportfolio%2Fport2.jpg?alt=media&token=64c3b21f-108d-43c9-948d-26f2d22968e1" alt="">
                <div class="hover-effect">
                  <div class="hover-content">
                    <a routerLink="/none"><i class="fa fa-search"></i></a>
                    <a routerLink="/none"><i class="fa fa-link"></i></a>
                   <h3 style="color: white;">C I A R</h3>
                   <h5 style="color: white;">Central Institute for Research on Buffaloes</h5>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 masonry-item all category-digital">
            <div class="case-item">
              <div class="case-thumb">
                <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Eportfolio%2Fport3.jpg?alt=media&token=97999133-c6a3-46e6-a728-e32c5a92c74a" alt="">
                <div class="hover-effect">
                  <div class="hover-content">
                    <a routerLink="/none"><i class="fa fa-search"></i></a>
                    <a routerLink="/none"><i class="fa fa-link"></i></a>
                    <h3 style="color: white;">Rishi Galav Public School</h3>
                    <h5 style="color: white;">Best School in Gwalior</h5>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 masonry-item all category-digital">
            <div class="case-item">
              <div class="case-thumb">
                <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Eportfolio%2Fvee.jpg?alt=media&token=66c083af-8a0b-4a18-8d83-3965d9db6743" alt="">
                <div class="hover-effect">
                  <div class="hover-content">
                    <a routerLink="/none"><i class="fa fa-search"></i></a>
                    <a href="https://vikasedugwl.com/" ><i class="fa fa-link"></i></a>
                    <h3 style="color: white;">VEE ACADEMY</h3>
                    <h5 style="color: white;">Gwalior</h5>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 masonry-item all category-analysis">
            <div class="case-item">
              <div class="case-thumb">
                <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Eportfolio%2Fport5.jpg?alt=media&token=41a3ba64-bfaa-4872-9445-e761d44fa94a" alt="">
                <div class="hover-effect">
                  <div class="hover-content">
                    <a routerLink="/none"><i class="fa fa-search"></i></a>
                    <a routerLink="/none"><i class="fa fa-link"></i></a>
                    <h3 style="color: white;"> Allure Constructions</h3>
                    <h5 style="color: white;">Allure Constructions Company</h5>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 masonry-item all category-analysis">
            <div class="case-item">
              <div class="case-thumb">
                <img src="https://firebasestorage.googleapis.com/v0/b/pn-images.appspot.com/o/home-%3Eportfolio%2FSonagir-logo.png?alt=media&token=5d2c5b91-e7a1-4ae3-a234-6f946dafd08d" style="height: 100%;" alt="">
                <div class="hover-effect">
                  <div class="hover-content">
                    <a routerLink="/none"><i class="fa fa-search"></i></a>
                    <a routerLink="/none"><i class="fa fa-link"></i></a>
                    <h3 style="color: white;">Sonagir</h3>
                    <h5 style="color: white;">Sonagir Website</h5>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-4 masonry-item all category-seo">
            <div class="case-item">
              <div class="case-thumb">
                <img src="assets/frontend/images/portfolio/handmade.png" >
                <div class="hover-effect">
                  <div class="hover-content">
                    <a routerLink="/none"><i class="fa fa-search"></i></a>
                    <a routerLink="/none"><i class="fa fa-link"></i></a>
                    <h3 style="color: white;">Hand Made Love</h3>
                    <h5 style="color: white;">Hand Made Love by Tanya</h5>
                  
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="col-lg-12">
        <!-- <div class="main-purple-button">
          <a href="portfolio-v1.html">View more Projects</a>
        </div> -->
      </div>
    </div>
  </div>
</section>